<template>
    <div class="body-container">
        <BannerSection title="Guía completa para empeñar<br class='d-none d-lg-block'/> tu carro en México" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        Optar por obtener liquidez a través de tu vehículo sin necesidad de venderlo o caer en
                        condiciones desfavorables es ahora una opción viable en México, gracias a servicios innovadores
                        como el que ofrece Ibancar. A diferencia de los empeños tradicionales, Ibancar propone un modelo
                        que evita las complicaciones y limitaciones habituales, asegurando un proceso justo,
                        transparente y beneficioso para el cliente. En esta guía, te explicaremos cómo puedes
                        beneficiarte de esta modalidad con Ibancar, destacando las ventajas y la documentación
                        necesaria.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Cómo y dónde empeñar tu carro por efectivo en México
                        </h2>
                    </span>

                    <p class="mb-0">
                        El primer paso para empeñar tu coche es encontrar un lugar confiable. Para encontrar la opción
                        más segura y conveniente, en Ibancar te facilitamos tres puntos clave que debes tener en cuenta
                        para no caer en trampas y cláusulas abusivas:
                    </p>
                    <br>
                    <ol>
                        <li>
                            <p class="mb-0 bold-text">
                                Investigación:
                            </p>
                            <span>Compara diferentes lugares dónde puedo empeñar mi carro y elige el que ofrezca las
                                mejores condiciones.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Valoración:
                            </p>
                            <span>Consulta las reseñas de otros usuarios. Recuerda que el valor de tu carro será
                                determinado por el prestamista, basado en el modelo, año y condición del
                                vehículo.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Acuerdo:
                            </p>
                            <span>Una vez tengas el lugar dónde empezar tu carro, asegúrate de entender todos los
                                términos y condiciones antes de proceder.</span>
                        </li>
                    </ol>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Ventajas de empeñar tu vehículo: Lo que necesitas saber</h2>

                    </span>
                    <p>Ibancar se destaca como una opción segura y conveniente, ofreciendo condiciones justas y
                        transparentes. Empeñar tu carro con Ibancar tiene sus ventajas, no sólo por su experiencia
                        probada de más de 6 años, sino también por la opinión de aquellos que han confiado en sus
                        servicios. Te las explicamos a continuación: </p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Acceso rápido a efectivo:
                                </span>Ideal para situaciones de emergencia donde necesitas dinero de forma inmediata.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Conservación de tu vehículo:
                                </span>Continúa usando tu coche sin restricciones, una diferencia clara al modelo de
                                empeño tradicional.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Proceso sencillo y rápido
                                </span>Menos burocracia y papeleo en comparación con otros tipos de préstamos. Además de
                                ser, un proceso de solicitud 100% en línea.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Amortización flexible:
                                </span> Ibancar permite amortizar el préstamo en cualquier momento, sin permanencia ni
                                penalizaciones, algo no visto en los empeños tradicionales que suelen requerir el pago
                                de alquileres sin amortizar el préstamo.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Cuotas fijas:
                                </span>A diferencia de otras opciones que pueden sorprender con cambios en los pagos,
                                Ibancar ofrece una cuota fija, facilitando la planificación financiera.

                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <p>¿Todavía te quedan dudas? ¡Sigue leyendo!</p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Documentación requerida para empeñar tu carro</h2>
                        
                    </span>
                    <p>Después de resolver el <b>¿dónde puedo empeñar mi carro?</b>; descubrir juntos las ventajas que
                        tiene empeñar tu vehículo, te identificamos aquella documentación que necesitas preparar y
                        presentar para poder conseguir liquidez: </p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Identificación oficial vigente:
                                </span>Como tu INE o pasaporte.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Factura del vehículo
                                </span>Que acredite tu propiedad sobre el carro.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Comprobante de domicilio:
                                </span>Reciente, para verificar tu lugar de residencia.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Seguro del vehiculo
                                </span> Algunos prestamistas también pueden solicitarlo.

                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                    <p>Empeñar tu carro en México es una opción conveniente y rápida para obtener efectivo, siempre y
                        cuando elijas el prestamista correcto. En Ibancar, nos esforzamos por ofrecerte un proceso
                        transparente, justo y libre de cláusulas abusivas, asegurando que obtengas las mejores
                        condiciones posibles.</p>
                    <p>Si a día de hoy, estás estudiando distintas opciones para valorar <b>dónde puedes empeñar tu carro</b>,
                        recuerda las características principales de Ibancar. Nos diferenciamos de otras empresas, por
                        ofrecer cuotas fijas y una posible amortización. Además de no tener una permanencia. Y si ya te
                        has decidido, recuerda preparar toda tu documentación con anticipación y leer detenidamente
                        todos los términos antes de firmar cualquier acuerdo.</p>
                        <br>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'GuiaCompletaEmpenarCarroEnMexico',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Dónde empeñar tu carro en México: Guía definitiva para obtener préstamos | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Descubre los mejores lugares para empeñar tu carro en México y obtener un préstamo rápido. Consejos, requisitos y ventajas de empeñar tu vehículo de forma segura.'
                    },
                    {
                        name: 'keywords',
                        content: 'donde puedoi empeñar mi carro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/guia-completa-empenar-carro-en-mexico', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>